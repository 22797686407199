<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo/>

            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                            <b-img
                                    fluid
                                    :src="imgUrl"
                                    alt="Login V2"
                            />
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <div class="logos mt-2">
                            <a href="#" title="Junta de Andalucía"><img
                                    src="https://www.traveltocostadelsol.com/public/images/junta-de-andalucia.jpg"
                                    alt="Junta de Andalucía" class="img-fluid" title="Junta de Andalucía"></a>
                            <a href="#" title="Fondo Europeo de desarrollo regional"><img
                                    src="https://www.traveltocostadelsol.com/public/images/union-europea.jpg"
                                    alt="Fondo Europeo de desarrollo regional" class="img-fluid"
                                    title="Fondo Europeo de desarrollo regional"></a>
                            <a href="#" title="Andalucía se mueve con Europa"><img
                                    src="https://www.traveltocostadelsol.com/public/images/andalucia-europa.jpg"
                                    alt="Andalucía se mueve con Europa" class="img-fluid"
                                    title="Andalucía se mueve con Europa"></a>
                            <p class="mt-2">Viajes Diversion Tours © · 2021 Todos los derechos reservados</p>
                        </div>
                    </div>
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Diversion Tours
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Inserta tu nombre de usuario y clave para acceder al sistema
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Contraseña</label>
                                    <b-link :to="{name:'auth-forgot-password-v2'}">
                                        <small>¿Recuperar contraseña?</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Recordarme
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="login"
                            >
                                Entrar
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        login() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    useJwt.login({
                        email: this.userEmail,
                        password: this.password,
                    })
                        .then(response => {
                            const userData = response.data
                            useJwt.setToken(response.data.accessToken)
                            useJwt.setRefreshToken(response.data.accessToken)
                            localStorage.setItem('userData', JSON.stringify(userData))
                            this.$router.push({ name: 'Dashboard' })
                        })
                }
            })
        },
    },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
